<template>
  <v-btn @click="cancel" outlined color="#616160" small :disabled="disabled">
    {{message}}
  </v-btn>
</template>

<script>
export default {
  name: 'CancelButton',
  props: ['cancel', 'message', 'disabled']
};
</script>
