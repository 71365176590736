<template>
  <v-main class="container-prosker">
    <div>
      <v-data-table
        :options.sync="pagination"
        :server-items-length="pagination.totalItems"
        :loading="loadingTable"
        :search="search" :headers="headers"
        :footer-props="{itemsPerPageText: $t('rowsPerPage')}"
        :items="categoriesList"
        class="elevation-1 mt-5"
        item-key="id"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>{{ $t('categories') }}</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-text-field :label="$t('search')" append-icon="search" hide-details
                          single-line v-model="search" color="#59D79D"></v-text-field>
            <v-spacer></v-spacer>
            <categories-dialog
                :item="editedItem"
                :on-close="onAddClose"
                :on-added="onAddCallback"
                :new-item="newItem"
                :show-add="showAdd"
            />
          </v-toolbar>
        </template>
        <template v-slot:no-data>
          <v-alert :value="true" color="error" icon="warning" class="white--text">
            <span class="white--text">
              {{ $t("noData") }}
            </span>
          </v-alert>
        </template>
        <template v-slot:no-results>
          <v-alert :value="true" color="error" icon="warning">
          <span class="white--text">
            {{ $t('yourSearchFor') }} "{{ search }}" {{ $t('foundNoResults') }}
          </span>
          </v-alert>
        </template>
        <template v-slot:item.img_url="{ item }">
          <v-img :src="item.img_url" alt="image" height="150" width="150" />
        </template>
        <template v-slot:item.is_default="{ item }">
          <v-icon :color="item.is_default ? 'teal' : 'red'">
            {{ item.is_default ? 'mdi-check-circle' : 'mdi-close-circle'}}
          </v-icon>
        </template>
        <template v-slot:item.action="{ item }">
          <tooltip-button
            :icon="'mdi-pencil'"
            :action="editItem"
            :item="item"
            :color="'secondary'"
            :message="'edit'"
          ></tooltip-button>
        </template>
      </v-data-table>
    </div>
  </v-main>
</template>
<script>
import axios from 'axios';
import CategoriesDialog from '@/components/socialvue/dialogs/CategoriesDialog';
import TooltipButton from '@/components/socialvue/buttons/TooltipButton.vue';
import _ from 'lodash';

export default {
  name: 'CategoriesList',
  components: { TooltipButton, CategoriesDialog },
  data () {
    return {
      categoriesList: [],
      showAdd: false,
      pagination: { totalItems: 0, per_page: 10, page: 1, sortBy: ['id'] },
      loadingTable: false,
      search: '',
      editedItem: {}
    };
  },
  created () {
    this.list();
  },
  computed: {
    headers () {
      return [
        { text: this.$t('name'), value: 'name' },
        { text: this.$t('image'), value: 'img_url' },
        { text: this.$t('category'), value: 'category_name' },
        { text: this.$t('isDefaultCategory'), value: 'is_default', align: 'center' },
        { text: this.$t('actions'), value: 'action', sortable: false }
      ];
    }
  },
  watch: {
    search: {
      handler () {
        this.resetPaginationForSearch(this);
      },
      deep: true
    },
    pagination: {
      handler () {
        this.list();
      },
      deep: true
    }
  },
  methods: {
    resetPagination () {
      this.pagination = { totalItems: 0, per_page: 10, page: 1, sortBy: ['id'] };
    },
    resetPaginationForSearch: _.debounce((self) => {
      self.resetPagination();
    }, 500),
    onAddClose () {
      this.showAdd = false;
      this.reset();
      this.editedItem.isEdit = false;
    },
    onAddCallback () {
      this.list();
    },
    reset () {
      this.editedItem = {};
    },
    newItem () {
      this.reset();
      this.showAdd = true;
      this.editedItem.isEdit = false;
    },
    editItem (item) {
      this.editedItem = item;
      this.editedItem.isEdit = true;
      this.showAdd = true;
    },
    list () {
      this.loadingTable = true;
      const url = `${process.env.VUE_APP_BASE_URL}/api/categories?search=${this.search}
      &per_page=${this.pagination.per_page}&page=${this.pagination.page}&sortBy=${this.pagination.sortBy[0]}`;
      axios.get(url).then((response) => {
        this.categoriesList = [];
        this.categoriesList = response.data.data;
        this.pagination.totalItems = response.data.total;
        this.loadingTable = false;
      }).catch((error) => {
        this.loadingTable = false;
        this.showError(error);
      });
    }
  }
};
</script>
