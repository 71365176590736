<template>
  <v-dialog
    v-model="showAdd"
    width="500"
    persistent
    @keydown.esc="onClose"
    @keydown.enter="save"
  >
    <template v-slot:activator="{ on }">
      <v-btn @click="onNewClicked" color="secondary" dark v-on="on">
          {{ $vuetify.breakpoint.mobile ? '+' : $t("createCategory") }}
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="text-h5 bg-prosk-secondary white--text">
        <span class="headline">
          {{ item.isEdit ? $t("editCategory") : $t("createCategory") }}
        </span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-col cols="12">
            <v-text-field
              :label="$t('name')"
              v-model="name"
              color="#59D79D"
              :hide-details="nameError.length === 0"
              :error-count="nameError.length"
              :error-messages="nameError"
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-file-input
              :label="$t('image')"
              accept="image/.pdf,.png,.jpg,.gif"
              color="#59D79D"
              :hide-details="imageError.length === 0"
              :error-count="imageError.length"
              :error-messages="imageError"
              prepend-icon="mdi-camera"
              v-model="img_url"
            ></v-file-input>
          </v-col>
          <v-col cols="12">
            <v-autocomplete
              :label="$t('categories')"
              :items="categories"
              v-model="parent_id"
              chips
              color="#59D79D"
              item-color="#59D79D"
              outlined
              hide-details
              deletable-chips
              :search-input.sync="searchInput"
              @change="searchInput = ''"
            ></v-autocomplete>
          </v-col>
          <v-col cols="12">
            <v-checkbox
                v-if="item.isEdit && !item.parent_id && !parent_id"
                v-model="is_default"
                :disabled="is_default"
                :label="$t('isDefaultCategory')"
                color="#59D79D"/>
          </v-col>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <cancel-button :cancel="onClose" :message="$t('cancel')"/>
        <save-button :save="save" :loading="loading" :message="$t('save')"/>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import errorDialog from '@/components/socialvue/mixins/errorDialog';
import SaveButton from '@/components/socialvue/buttons/SaveButton.vue';
import CancelButton from '@/components/socialvue/buttons/CancelButton.vue';

export default {
  name: 'CategoriesDialog',
  components: { CancelButton, SaveButton },
  props: ['showAdd', 'onClose', 'item', 'newItem', 'onAdded'],
  mixins: [errorDialog],
  data () {
    return {
      loading: false,
      is_default: false,
      name: '',
      img_url: null,
      parent_id: '',
      searchInput: '',
      categories: [],
      imageError: [],
      nameError: []
    };
  },
  created () {
    this.getCategoriesData();
  },
  watch: {
    parent_id: function (value) {
      if (value) {
        this.is_default = false;
      }
    },
    showAdd: function (value) {
      if (value && this.item && this.item.isEdit) {
        this.name = this.item.name;
        this.is_default = this.item.is_default;
        this.parent_id = this.item.parent_id;
      }
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser'
    })
  },
  methods: {
    onNewClicked () {
      this.resetErrors();
      this.reset();
      this.newItem();
    },
    getCategoriesData () {
      const url = process.env.VUE_APP_BASE_URL + '/api/categories';
      this.categories.splice(0, this.categories.length);
      axios.get(url).then((response) => {
        response.data.data.forEach((category) => {
          if (!category.parent_id) {
            category.value = category.id;
            category.text = category.name;
            this.categories.push(category);
          }
        });
      });
    },
    getFormData () {
      const formData = new FormData();
      if (this.img_url != null) {
        formData.append('img_url', this.img_url);
      }
      return formData;
    },
    addImage (id) {
      const url = `${process.env.VUE_APP_BASE_URL}/api/users/${this.currentUser.id}/categories/${id}/medias`;
      const image = this.getFormData();
      axios
        .post(url, image)
        .then(() => {
          this.loading = false;
          this.onClose();
          this.onAdded();
          this.reset();
        })
        .catch((error) => {
          this.loading = false;
          this.showError(error);
        });
    },
    reset () {
      this.name = '';
      this.img_url = null;
      this.parent_id = '';
    },
    resetErrors () {
      this.imageError = [];
      this.nameError = [];
    },
    save () {
      this.resetErrors();
      const url = `${process.env.VUE_APP_BASE_URL}/api/users/${this.currentUser.id}/categories`;
      const formData = {
        name: this.name,
        is_default: this.is_default,
        parent_id: this.parent_id
      };
      if (this.name === '') {
        this.nameError.push(this.$t('fieldNameRequired'));
      } else {
        this.loading = true;
        if (!this.item.isEdit) {
          axios
            .post(url, formData)
            .then((res) => {
              this.addImage(res.data.id);
            })
            .catch((error) => {
              this.loading = false;
              this.showError(error);
            });
        } else {
          const url = `${process.env.VUE_APP_BASE_URL}/api/users/${this.currentUser.id}/categories/${this.item.id}`;
          axios
            .put(url, formData)
            .then(() => {
              if (this.img_url !== null) {
                this.addImage(this.item.id);
              } else {
                this.reset();
                this.loading = false;
                this.onClose();
                this.onAdded();
              }
            })
            .catch((error) => {
              this.loading = false;
              this.showError(error);
            });
        }
      }
    }
  }
};
</script>
